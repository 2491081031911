export const LIST_ACTOR = 'LIST-ACTOR';

export const ADD_DOCUMENT = 'ADD-DOCUMENT';

export const DOWNLOAD_DOCUMENT = 'DOWNLOAD-DOCUMENT';

export const READ_DOCUMENT = 'READ-DOCUMENT';

export const LIST_GROUP_2 = 'LIST-GROUP-2';

export const LIST_GROUP_1 = 'LIST-GROUP-1';
