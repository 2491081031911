import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Menu } from 'antd';
import {
  TeamOutlined,
  UserOutlined,
  DashboardOutlined,
  FolderOpenOutlined,
} from '@ant-design/icons';


const { Sider } = Layout;

export default function SideBar({ collapsed }) {
  const navigate = useNavigate();
  const [userAuth, setUserAuth] = useState(JSON.parse(localStorage.getItem('AUTH_USER')));
  const [items, setItems] = useState([]);

  useEffect(() => {
    listItems(userAuth?.RL);
  }, [userAuth]);

  const listItems = (roleAuth) => {
    if(roleAuth === 'ADMIN'){
      setItems([
        {
          key: '1',
          icon: <DashboardOutlined />,
          label: 'Tableau de bord',
          onClick : () => navigate('/dashboard'),
        },
        {
          key: '4',
          icon: <TeamOutlined />,
          label: 'Groupes',
          onClick : () => navigate('/groupes'),
        },
        {
          key: '5',
          icon: <UserOutlined />,
          label: 'Utlisateurs',
          onClick : () => navigate('/users'),
        },
        {
          key: '6',
          icon: <FolderOpenOutlined />,
          label: 'Documents',
          onClick : () => navigate('/document'),
        },
      ])
    } else {
      setItems([
        {
          key: '1',
          icon: <DashboardOutlined />,
          label: 'Tableau de bord',
          onClick : () => navigate('/dashboard'),
        },
        {
          key: '3',
          icon: <FolderOpenOutlined />,
          label: 'Documents',
          onClick : () => navigate('/document'),
        },
      ])
    }
  }
  return (
    <>
      <Sider trigger={null} collapsible collapsed={collapsed} style={{height: '98.3vh', overflowX: 'hidden', background: '#fff'}}>
        {
          collapsed ? (
            <div style={{padding: '10% 0 12% 37%'}}>
              <img alt="logo" src="./assets/images/D.svg" width={20} />
            </div>
          ) : (
            <div style={{margin: '10% 0 12% 12%'}}>
              <img alt="logo" src="./assets/images/logo.svg" width={150} />
            </div>
          )
        }
        <Menu
          style={{
            background: '#fff',
            boxShadow: 'none',
            borderInlineEnd: 'none'
          }}
          theme="light"
          mode="inline"
          defaultSelectedKeys={['1']}
          items={items}
        />
      </Sider>
    </>
  );
}