import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Input, Button, Space, Typography, message, notification  } from 'antd';
import { HomeOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import useWebSocket, { ReadyState } from 'react-use-websocket';

const BASE_WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;

const backgroundStyle = {
  backgroundImage: 'url(/img/others/img-17.jpg)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};
const { Text } = Typography;
const Login = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  // const [sMail, setSMail] = useState("");
  // const [sMsgRet, setSMsgRet] = useState("");
  // const [sMsgMail, setSMsgMail] = useState("");
  const navigate = useNavigate();

  const [socketUrl, setSocketUrl] = useState(`${BASE_WEBSOCKET_URL}/authentication`);
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);

  const handleClickSendMessage = React.useCallback(() => {
    setLoading(true);
    const fields = form.getFieldsValue();
    const data = {
      "sCle":"0-0", 
      "sOpe":"99", 
      "nTaille":0, 
      "Data": {
        "S1":fields.sNomOrga,
        "S2":fields.sLogin,
        "Ca":fields.password
      },
      "sCP":"log"
    };

    sendMessage(JSON.stringify({flag: 'LOGIN', data}));
    
  }, [sendMessage, form]);

  useEffect(() => {
    if (lastMessage !== null) {
      const response = JSON.parse(lastMessage.data);
      setLoading(false);
      if(response.sMsg && response.sMsg === "OK") {
        //  Formule token « D » + nIDses + tiret + N1 + tiret + sUUID
        localStorage.setItem('token', `D${response.Data.nIDses}-${response.Data.N1}-${response.Data.sUUID}`);
        localStorage.setItem('AUTH_USER', JSON.stringify(response.Data));
        notification.success({message: "Connecté avec succès"});
        navigate("/dashboard");
      }
      if(response.message && response.message === "Authentication Fail"){
        notification.error({message: "Échec de l'authentification, vérifier les identifiants"});
      }
    }
  }, [lastMessage, navigate]);

  return (
    <div className='auth-container'>
        <div className="h-100" style={backgroundStyle}>
        <div className="container d-flex flex-column justify-content-center h-100">
            <Row justify="center">
            <Col xs={20} sm={20} md={20} lg={9}>
                <Card>
                <div className="my-2">
                    <div className="text-center" style={{textAlign: 'center'}}>
                        <Button shape="circle" icon={<UserOutlined />} size="large" style={{minWidth: '50px'}}/>
                        <h1 className="mt-2 mb-3 text-center font-weight-bold">Se connecter</h1>
                    </div>
                    <Row justify="center">
                    <Col xs={24} sm={24} md={20} lg={20}>
                    </Col>
                    <Col xs={24} sm={24} md={20} lg={20}>
                        <Form
                        form={form}
                        layout="vertical"
                        name="login-form"
                        onFinish={handleClickSendMessage}
                        data-testid="LoginForm"
                        >
                        <Form.Item
                            name="sNomOrga"
                            label='Organisation'
                            rules={[
                            {
                                required: true,
                            },
                            ]}
                        >
                            <Input prefix={<HomeOutlined className="text-primary" />} />
                        </Form.Item>
                        <Form.Item
                            name="sLogin"
                            label="Login"
                            rules={[
                            {
                                required: true
                            },
                            ]}
                        >
                            <Input prefix={<UserOutlined className="text-primary" />} />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label="Mot de passe"
                            rules={[
                            {
                                required: true
                            },
                            ]}
                        >
                            <Input.Password prefix={<LockOutlined className="text-primary" />} />
                        </Form.Item>

                        <Form.Item>
                            <Button id="submit" type="primary" htmlType="submit" loading={loading} block>
                                Connexion
                            </Button>
                        </Form.Item>
                        </Form>
                    </Col>
                    </Row>
                    <Row justify="center" className="mb-4">
                  <Space>
                    <Link
                        to={{
                            pathname: `/auth/forgot-password`,
                        }}
                    >
                        <Button
                        type="link"
                        >
                        Mot de passe oublié
                        </Button>
                    </Link>
                  </Space>
                </Row>
                </div>
                </Card>
            </Col>
            </Row>
        </div>
        </div>
    </div>
  );
};

export default Login;
