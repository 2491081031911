import React, { useState, useEffect } from 'react';
import { Card, Button, Table, Typography, Space, Input, Modal, Form, notification } from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import moment from 'moment';
import useWebSocket from 'react-use-websocket';
import { GROUP_URI } from '../../../constants/endpoints';
import NewGroup from "../../../components/Groupes/NewGroup";
const BASE_WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;
const userAuth = JSON.parse(localStorage.getItem('AUTH_USER'))

const { Title } = Typography;
const { Search } = Input;

const Groupes = (props) => {
    const [form] = Form.useForm();
    const [word, setWord] = useState("");
    const [roleAuth, setRoleAuth] = useState('');
    const [data, setData] = useState(null);
    const [dataDoc, setDataDoc] = useState(null);
    const [dataInfoGroup, setDataInfoGroup] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenInfo, setIsModalOpenInfo] = useState(false);
    const [isModalOpenInfoGlobal, setIsModalOpenInfoGlobal] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState({});
    
    const { sendMessage: sendDataGroup, lastMessage: responseGroup } = useWebSocket(GROUP_URI , {shouldReconnect: (closeEvent) => true});
    const { sendMessage: sendDataGroupInfo, lastMessage: responseGroupInfo } = useWebSocket(GROUP_URI , {shouldReconnect: (closeEvent) => true});
    const { sendMessage: sendDataCreateGroup, lastMessage: responseCreateGroup } = useWebSocket(GROUP_URI , {shouldReconnect: (closeEvent) => true});
    const { sendMessage: sendDataDoc, lastMessage: responseDoc } = useWebSocket(GROUP_URI , {shouldReconnect: (closeEvent) => true});

    const [loading, setLoading] = useState(false);
    const [loadedList, setLoadedList] = useState(false);
    const [loadedInfo, setLoadedInfo] = useState(false);
    const [loadedCreate, setLoadedCreate] = useState(false);
    const [loadedListDoc, setLoadedListDoc] = useState(false);
    
    
    const fetchListGroup = React.useCallback(async () => {
        setLoading(true);
        const data = {
          "sCle": localStorage.getItem('token'),
          "sOpe":"11-2",
          "nTaille":0,
          "Data":{}, 
          "sCP":"drggp"
        };
    
        sendDataGroup(JSON.stringify({flag: 'LIST-GROUP-2', data}));
    }, [sendDataGroup]);

    const fetchGroupInfo = React.useCallback(async (nIDGrp) => {
      setDataInfoGroup(null);
      const data = {
        "sCle": localStorage.getItem('token'),
        "sOpe":"1-1",
        "nTaille":0,
        "Data":{
          "nIDGrp" : nIDGrp
        }, 
        "sCP":"drggp"
      };
  
      sendDataGroupInfo(JSON.stringify({flag: 'ALL-INFOS-GROUP', data}));
  }, [sendDataGroupInfo]);

    const fetchListDocument = React.useCallback(() => {
      const data = {
          "sCle": localStorage.getItem('token'),
          "sOpe": '10',
          "nTaille": 0,
          "Data": {},
          "sCP":"drggp"
      };
  
      sendDataDoc(JSON.stringify({"flag":"LIST-DOCUMENT", data}));
    }, [sendDataDoc]);

    const handleResponseGroup = React.useCallback(() => {
      if (responseGroup !== null) {
        const response = JSON.parse(responseGroup.data);
  
        setLoading(false);
        
        if(response.status && response.status === 200) {
          if(!loadedList) {
            setLoadedList(true)
  
            setData(response.data.Data);
          }
        }
        if(response.status && response.status === 400){
          notification.error({message: response.data.sMsg});
          setData([]);
        }
      }
    }, [loadedList, responseGroup, setData]);
  
    const handleResponseDoc = React.useCallback(async () => {
      if (responseDoc !== null) {
        const response = JSON.parse(responseDoc.data);
        
        setLoading(false);
        
        if(response.status && response.status === 200) {
          console.log(response.data)
          if(!loadedListDoc) {
            
            setLoadedListDoc(true)
            setDataDoc(response.data?.Data?.tabNomTabDoc);
          }
        }
        if(response.status && response.status === 400){
          notification.error({message: response.data.sMsg});
          setDataDoc([]);
        }
      }
    }, [loadedListDoc, responseDoc, setDataDoc]);

    const handleResponseCreateGroup = React.useCallback(() => {
      if (responseCreateGroup !== null) {
        const response = JSON.parse(responseCreateGroup.data);
  
        setLoading(false);
        
        if(response.status && response.status === 200) {
          if(!loadedCreate) {

            setLoadedCreate(true)
            notification.success({message: "Opération avec succès."});
            
            setTimeout(() => {
              window.location.reload();
            }, 500);
          }
        }
        if(response.status && response.status === 400){
          notification.error({message: response.data.sMsg});
          // setData([]);
        }
      }
    }, [loadedCreate, responseCreateGroup]);

    const handleResponseGroupInfo = React.useCallback(() => {
      if (responseGroupInfo !== null && dataInfoGroup === null) {
        const response = JSON.parse(responseGroupInfo.data);
        console.log('ATOO ', response.data)
        setLoadedInfo(false);
        
        if(response.status && response.status === 200) {
          if(!loadedInfo) {
            
            setDataInfoGroup(response.data?.Data);
            setLoadedInfo(true)
          }
        }
        if(response.status && response.status === 400){
          notification.error({message: response.data.sMsg});
          // setData([]);
        }
      }
    }, [loadedInfo, responseGroupInfo, setDataInfoGroup]);


  const handleCancelInfo = () => {
    setIsModalOpenInfo(false);
  } 
  
  const handleCancelInfoGlobal = () => {
    setIsModalOpenInfoGlobal(false);
    setLoadedInfo(false);
  } 

    useEffect(() => {
      if (responseGroup === null && data === null) {
        fetchListGroup();
      }
  
      handleResponseGroup();
      handleResponseDoc();

      handleResponseCreateGroup();

      handleResponseGroupInfo();
  
      setRoleAuth(userAuth?.RL);
    }, [data, userAuth, fetchListGroup, responseGroup, handleResponseGroup, handleResponseDoc, handleResponseCreateGroup, handleResponseGroupInfo]);


  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showModal = () => {
    fetchListDocument();
    form.setFieldsValue({
      nIDGrp: 0,
      sNomGrp: '',
      sAliasGrp: '',
      dhDateDeb: '',
      dhDateFin: '',
      bEstVisible: true,
    });
    setIsModalOpen(true);
  };

  const submitCreate = (values, mode) => {
    console.log(values, ' - ', mode);

    if(values.dhDateDeb != null) {
      const inputDateDeb = moment(values.dhDateDeb, 'YYYY-MM-DD');
      values.dhDateDeb = inputDateDeb.format('YYYY-MM-DDTHH:mm:ss.SSS');
    }
    if(values.dhDateFin != null) {
      const inputDateFin = moment(values.dhDateFin, 'YYYY-MM-DD');
      values.dhDateFin = inputDateFin.format('YYYY-MM-DDTHH:mm:ss.SSS');
    }
    
    values.bEstVisible = true;
    let data = {};
    if(mode === "create"){
      data = {
        "sCle": localStorage.getItem('token'),
        "sOpe": '2',
        "nTaille": 0,
        "Data": values,
        "sCP":"drggp"
      };
      sendDataCreateGroup(JSON.stringify({"flag":"CREATE-GROUP", data}));
    }
    if(mode === "edit"){
      data = {
        "sCle": localStorage.getItem('token'),
        "sOpe": '3-1',
        "nTaille": 0,
        "Data": values,
        "sCP":"drggp"
      };
      sendDataCreateGroup(JSON.stringify({"flag":"UPDATE-GROUP", data}));
    }
    
  };

  const columns = [
    {
      title: 'Nom',
      dataIndex: 'sNomGrp',
      key: 'sNomGrp',
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'ascend',
      render: (text, row) => {
        return (
            <>
                <span>{text}</span> 
                <Button
                type="link"
                onClick={() => showModalInfo(row.nIDGrp)}
                >
                    <EditOutlined />
                </Button>
            </>
        );
      },
    },
    {
        title: 'Table document ',
        dataIndex: 'sAliasGrp',
        key: 'sAliasGrp',
    },
    {
        title: 'Date début',
        dataIndex: 'dhDateDeb',
        key: 'dhDateDeb',

        sortDirections: ['descend', 'ascend'],
        defaultSortOrder: 'ascend',
    },
    {
        title: 'Date fin',
        dataIndex: 'dhDateFin',
        key: 'dhDateFin',

        sortDirections: ['descend', 'ascend'],
        defaultSortOrder: 'ascend',
    },
    {
      title: 'Actions',
      dataIndex: 'nIDGrp',
      key: 'nIDGrp',
      render: (text, row) => {
        return (
          <>
              <Button className="mr-3" onClick={() => showModalInfoGlobal(text)} type="link" icon={<EyeOutlined />} size="small" style={{width: '24px', height: '24px'}}/>
          </>
        );
      },
    },
  ];
  
  const showModalInfoGlobal = (id) => {
    setDataInfoGroup(null);
    fetchGroupInfo(id);
    setIsModalOpenInfoGlobal(true);
  }
  const showModalInfo = (nIDGrp) => {
    let selected = data.find((item) => nIDGrp === item.nIDGrp);
    form.setFieldsValue({
      nIDGrp: selected.nIDGrp,
      sNomGrp: selected.sNomGrp,
      sAliasGrp: selected.sAliasGrp,
      bEstVisible: selected.bEstVisible,
    });
    setSelectedGroup(selected);
    setIsModalOpenInfo(true);
  }



  const onSearch = (value) => {
    const text = value.toLowerCase();
    setWord(text);
  };
  return (
    <div>
      
      <Card className='py-2' bodyStyle={{padding: '0px 25px'}}  style={{border: '1px dashed #D7D7D7'}}>
          <div className='d-flex justify-content-between py-2 align-items-center'>
              <Title level={3} className='my-1'>Gestion de groupe</Title>
              <div className='d-flex align-items-center'>
                  <Space direction="vertical">
                      <Search
                          placeholder="Rechercher"
                          enterButton
                          onKeyUp={(event) => onSearch(event.target.value)}
                      />
                  </Space>
                  <Button
                      type="primary"
                      onClick={() => showModal()}
                      className="btn-shadow"
                      >
                      + Ajouter groupe
                  </Button>
              </div>
          </div>
      </Card>
      <Card  style={{border: '1px dashed #D7D7D7'}}>
      <Table
          columns={columns}
          dataSource={data !== null && data?.filter((item) => {
          if (
              item.sNomGrp.toLowerCase().includes(word) ||
              item.sAliasGrp.toLowerCase().includes(word)
          ) {
              return true;
          }
          return false;
          })}
          pagination={{ hideOnSinglePage: true, pageSize: 5 }}
      />
      </Card>

      {/* modal create/edit */}
      <Modal 
          title="Ajout Groupe" 
          open={isModalOpen} 
          footer={null} 
          onCancel={handleCancel}
          maskClosable={false}
      >
          <NewGroup form={form} submitForm={submitCreate} dataDoc={dataDoc} mode='create'/>
      </Modal>

      <Modal title="Modification Groupe" open={isModalOpenInfo} maskClosable={false} onCancel={handleCancelInfo} footer={null} >
          {/* <p>{`Nom : ${selectedGroup?.sNomGrp}`}</p>
          <p>{`Table Document : ${selectedGroup?.sAliasGrp}`}</p>
          <p>{`Visible : ${selectedGroup?.bEstVisible}`}</p> */}
          <NewGroup form={form} submitForm={submitCreate} dataDoc={dataDoc} mode='edit'/>
      </Modal>

      <Modal 
      width={1000}
      title="Information Groupe" open={isModalOpenInfoGlobal} maskClosable={false} onCancel={handleCancelInfoGlobal} footer={null} >
          <p><b>Nom : </b>{`${dataInfoGroup && dataInfoGroup?.stGrp?.sNomGrp}`}</p>
          <p><b>Table Document : </b>{`${dataInfoGroup && dataInfoGroup?.stGrp?.sAliasGrp}`}</p>
          <p><b>Visible : </b>{`${dataInfoGroup && (dataInfoGroup?.stGrp?.bEstVisible === true) ? "Oui" : "Nom"}`}</p>
          <h4 className='mb-3'>Liste des acteurs rattachés</h4>
          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <tr>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Nom et prénom</th>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Mail</th>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Peut Lister</th>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Peut Lire</th>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Peut Deposer</th>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Peut Telecharger</th>
            </tr>
            {
              dataInfoGroup?.tabActDroit.map((item, index) => {
                return (
                  <tr key={index}>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                      {item?.stActeur?.sNom} {item?.stActeur?.sPrenom}
                    </td>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                      {item?.stActeur?.sMail}
                    </td>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                      { (item?.bPeutLister) ? "Oui" : "Non"}
                    </td>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                      { (item?.bPeutLire) ? "Oui" : "Non"}
                    </td>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                      { (item?.bPeutDeposer) ? "Oui" : "Non"}
                    </td>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                      { (item?.bPeutTelecharger) ? "Oui" : "Non"}
                    </td>
                  </tr>
                )}
              )
            }
          </table>
      </Modal>
    </div>
  );
};


export default Groupes
