import { ReactNode, useMemo } from "react";
import { AuthContext } from "../context";
// import { userHooks } from "../network";

const AuthProvider = ({ children }) => {
  // const { data, isLoading: loadingClient } = userHooks.useGetMe();

  const client = useMemo(() => {
    return JSON.parse(localStorage.getItem('AUTH_PROVIDER')) ?? {};
  }, []);

  return (
    <AuthContext.Provider value={{ client }}>
      {children}
    </AuthContext.Provider>
  );
};
export default AuthProvider;