import React, { useState } from "react";
import SideBar from "../Sidebar";
import "./index.module.css";

import { Layout, theme } from 'antd';
import HeaderBar from "../HeaderBar";
const { Content } = Layout;

export default function DefaultLayout({ children }) {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Layout>
      <SideBar collapsed={collapsed} />
      <Layout>
        <HeaderBar colorBgContainer={colorBgContainer} setCollapsed={setCollapsed} collapsed={collapsed} />
        <Content
          style={{
            margin: '5px 16px',
            padding: 24,
            minHeight: 280,
            overflowY: "auto",
            height: '85vh'
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
}