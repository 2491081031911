import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context";

import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DownOutlined
} from '@ant-design/icons';

import { Button, Layout, Space, Dropdown, Menu } from "antd";

import "./index.module.css";


const { Header } = Layout;

export default function HeaderBar({ collapsed, setCollapsed, colorBgContainer }) {
  const navigate = useNavigate();
  // const { client, loadingClient } = React.useContext(AuthContext);
  const [userAuth, setUserAuth] = useState(JSON.parse(localStorage.getItem('AUTH_USER')));

  const disconnect = async () => {
    localStorage.removeItem('token')
    localStorage.removeItem('AUTH_USER')
    localStorage.removeItem('lastNavigate')
    navigate('/')
  }
  const profileMenu = (
    <Menu>
      <Menu.Item key="1">
        <Button onClick={null} type="link">
          Mon profil
        </Button>
      </Menu.Item>
      <Menu.Item key="2">
        <Button onClick={() => disconnect()} type="link">
          Déconnexion
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Header
      style={{
        padding: 0,
        background: colorBgContainer,
      }}
      className="d-flex justify-content-between align-items-center"
    >
      <Button
        type="link"
        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        onClick={() => setCollapsed(!collapsed)}
        style={{
          fontSize: '16px',
          width: 64,
          height: 64,
          border: 'none'
        }}
      />

      <Dropdown
          overlay={
            profileMenu
          } 
          trigger={['click']}
        >
        <Button onClick={(e) => e.preventDefault()} type="link">
          <Space>
            {userAuth?.sNomUser}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </Header>
  );
}