import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import DefaultLayout from "./components/Layout/DefaultLayout";
import { publicRoutes, privateRoutes } from "./routes";

import PrivateRoutes from "./components/PrivateRoutes";
import ProtectedRoutes from "./components/ProtectedRoutes";
import AuthProvider from "./provider/AuthProvider";
import { ConfigProvider } from 'antd';


function App() {
  useEffect(() => {
    document.title = 'Dataroom';
  }, []);
  return (
    <ConfigProvider theme={{ token: { colorPrimary: '#29B489' } }}>
      <AuthProvider>
        <Router>
          <div className="App">
            <Routes>
              {publicRoutes.map((route, index) => {
                const PageNoLayout = route.component;

                return (
                  <Route
                    key={index} path='/' element={<ProtectedRoutes />}>
                    <Route
                      path={route.path}
                      element={
                        <PageNoLayout />
                      }
                    />
                  </Route>
                )
              })}
              {privateRoutes.map((route, index) => {
                const Page = route.component;

                return (
                  <Route
                    key={index} path='/' element={<PrivateRoutes />}>
                    <Route
                      path={route.path}
                      element={
                        <DefaultLayout>
                          <Page />
                        </DefaultLayout>
                      }
                    />
                  </Route>
                );
              })}
              <Route path="*" element={<div>404</div>} />
            </Routes>
          </div>
        </Router>
      </AuthProvider>
    </ConfigProvider>
  );
}

export default App;