import Dashboard from "../pages/app-views/Dashboard";
import Groupes from "../pages/app-views/groupes";
import Users from "../pages/app-views/users";
import Authentication from "../pages/auth-views/login";
import ForgotPassword from "../pages/auth-views/forgot-password";
import Document from "../pages/app-views/Document";


const privateRoutes = [
  { path: "/", component: Dashboard },
  { path: "/dashboard", component: Dashboard },
  { path: "/groupes", component: Groupes },
  { path: "/users", component: Users },
  { path: "/test", component: Dashboard },
  { path: "/document", component: Document },
];
const publicRoutes = [
  { path: "/", component: Authentication },
  { path: "/auth/login", component: Authentication },
  { path: "/auth/forgot-password", component: ForgotPassword },
]

export { publicRoutes, privateRoutes };