import React, { useEffect, useState } from "react";
import { Select, Input, Form, Button, DatePicker } from 'antd';


export default function NewGroup(props) {

    return (
        <>
            <Form
                form={props.form}
                layout="vertical"
                name="login-form"
                onFinish={(e) => props.submitForm(e, props.mode)}
                data-testid="groupForm"
                footer={null}
            >
                <Form.Item
                    name="nIDGrp"
                    label="nIDGrp"
                    rules={[
                    {
                        required: true,
                    },
                    ]}
                    hidden
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="sNomGrp"
                    label="Nom du groupe"
                    rules={[
                    {
                        required: true,
                    },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="sAliasGrp"
                    label="Table document"
                    className="mb-5"
                    rules={[
                    {
                        required: true,
                    },
                    ]}
                >
                    <Select
                        placeholder="Séléctionner table"
                        allowClear
                        options={[
                            {
                                value: 'WHDoc01',
                                label: 'WHDoc01',
                            },
                            {
                                value: 'WHDoc02',
                                label: 'WHDoc02',
                            },
                            {
                                value: 'WHDoc03',
                                label: 'WHDoc03',
                            },
                            {
                                value: 'WHDoc04',
                                label: 'WHDoc04',
                            },
                            {
                                value: 'WHDoc05',
                                label: 'WHDoc05',
                            },
                            {
                                value: 'WHDoc06',
                                label: 'WHDoc06',
                            },
                            {
                                value: 'WHDoc07',
                                label: 'WHDoc07',
                            },
                            {
                                value: 'WHDoc08',
                                label: 'WHDoc08',
                            },
                            {
                                value: 'WHDoc09',
                                label: 'WHDoc09',
                            },
                            {
                                value: 'WHDoc10',
                                label: 'WHDoc10',
                            },
                            {
                                value: 'WHDoc11',
                                label: 'WHDoc11',
                            },
                            {
                                value: 'WHDoc12',
                                label: 'WHDoc12',
                            },
                            {
                                value: 'WHDoc13',
                                label: 'WHDoc13',
                            },
                            {
                                value: 'WHDoc14',
                                label: 'WHDoc14',
                            },
                            {
                                value: 'WHDoc15',
                                label: 'WHDoc15',
                            },
                            {
                                value: 'WHDoc16',
                                label: 'WHDoc16',
                            },
                            {
                                value: 'WHDoc17',
                                label: 'WHDoc17',
                            },
                            {
                                value: 'WHDoc18',
                                label: 'WHDoc18',
                            },
                            {
                                value: 'WHDoc19',
                                label: 'WHDoc19',
                            },
                            {
                                value: 'WHDoc20',
                                label: 'WHDoc20',
                            },
                            
                        ]}
                    />
                </Form.Item>
                <Form.Item
                    name="dhDateDeb"
                    label="Date début"

                >
                    <input type="date" className="ant-input w-75" />
                </Form.Item>
                <Form.Item
                    name="dhDateFin"
                    label="Date fin"

                >
                    <input type="date" className="ant-input w-75" />
                </Form.Item>
                <Form.Item className="mt-5" style={{ textAlign: 'center' }}>
                    <Button id="submit" type="primary" htmlType="submit" block className="w-50">
                        Enregistrer
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}