import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Input, Button, message, Space, Typography  } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const backgroundStyle = {
  backgroundImage: 'url(/img/others/img-17.jpg)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};
const { Text } = Typography;
const ForgotPassword = (props) => {
  const { t } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [sMail, setSMail] = useState("");
  const [sMsgRet, setSMsgRet] = useState("");
  const [sMsgMail, setSMsgMail] = useState("");

  useEffect(() => {
  }, []);

  const onLogin = (values) => {
    console.log(values)
  };

  return (
    <div className='auth-container'>
        <div className="h-100" style={backgroundStyle}>
        <div className="container d-flex flex-column justify-content-center h-100">
            <Row justify="center">
            <Col xs={20} sm={20} md={20} lg={9}>
                <Card>
                <div className="my-2">
                    <div className="text-center">
                    <img className="img-fluid" src="/img/new_logo.png" alt="" />
                    <h3 className="mt-3 font-weight-bold">
                        Mot de passe oublié
                    </h3>
                    </div>
                    <Row justify="center">
                    <Col xs={24} sm={24} md={20} lg={20}>
                    </Col>
                    <Col xs={24} sm={24} md={20} lg={20}>
                        <Form
                        form={form}
                        layout="vertical"
                        name="login-form"
                        onFinish={onLogin}
                        data-testid="LoginForm"
                        >
                        <Form.Item
                            name="sNomOrga"
                            label="Organisation"
                            rules={[
                            {
                                required: true,
                            },
                            ]}
                        >
                            <Input prefix={<MailOutlined className="text-primary" />} />
                        </Form.Item>
                        <Form.Item
                            name="sLogin"
                            label="Login"
                            rules={[
                            {
                                required: true,
                            },
                            ]}
                        >
                            <Input prefix={<MailOutlined className="text-primary" />} />
                        </Form.Item>

                        <Form.Item>
                            <Button id="submit" type="primary" htmlType="submit" block>
                            Envoyer
                            </Button>
                        </Form.Item>
                        </Form>
                    </Col>
                    </Row>
                    <Row justify="center" className="mb-4">
                    <Space>
                        <Link
                            to={{
                                pathname: `/auth/login`,
                            }}
                        >
                            <Button
                            type="link"
                            >
                                Se connecter
                            </Button>
                        </Link>
                    </Space>
                    </Row>
                </div>
                </Card>
            </Col>
            </Row>
        </div>
        </div>
    </div>
  );
};


export default ForgotPassword
