import React, { useState, useEffect } from 'react'
import { Button, Card, Table, Typography, notification, Modal, Form, Select, Input, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import { DOCUMENT_URI, GROUP_URI } from '../../../constants/endpoints';
import { ADD_DOCUMENT, DOWNLOAD_DOCUMENT, LIST_GROUP_1, READ_DOCUMENT } from '../../../constants/flags';

const { Title } = Typography;
const options = [];
for (let i = 10; i < 36; i++) {
  options.push({
    label: i.toString(36) + i,
    value: i.toString(36) + i,
  });
}

function Document(props) {
  const formRef = React.createRef();
  const formRef2 = React.createRef();
  const [roleAuth, setRoleAuth] = useState('');
  const [userAuth, setUserAuth] = useState(JSON.parse(localStorage.getItem('AUTH_USER')));

  const [loadedList, setLoadedList] = useState(false);
  const [loadedDonwloadFile, setLoadedDonwloadFile] = useState(false);
  const [loadedReadDoc, setLoadedReadDoc] = useState(false);
  const [loadingDonwloadFile, setLoadingDonwloadFile] = useState(false);
  const [loadedListGroup, setLoadedListGroup] = useState(false);
  const [dataDoc, setDataDoc] = useState(null);
  const [readDoc, setReadDoc] = useState(null);
  const [dataDownloadDoc, setDataDownloadDoc] = useState(null);
  const [idMetadata, setIdMetadata] = useState(0);
  const [idMetadataRead, setIdMetadataRead] = useState(0);
  
  const [dataGroup, setDataGroup] = useState(null);
  const [open, setOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  
  const [selectedGroup, setSelectedGroup] = useState(null);

  const { sendMessage: sendDataDoc, lastMessage: responseDoc } = useWebSocket(DOCUMENT_URI, {shouldReconnect: (closeEvent) => true,});
  const { sendMessage: sendDownloadDoc, lastMessage: responseDownloadDoc } = useWebSocket(DOCUMENT_URI, {shouldReconnect: (closeEvent) => true,});
  const { sendMessage: sendReadDoc, lastMessage: responseReadDoc } = useWebSocket(DOCUMENT_URI, {shouldReconnect: (closeEvent) => true,});
  const { sendMessage: sendDataGroup, lastMessage: responseGroup } = useWebSocket(GROUP_URI, {shouldReconnect: (closeEvent) => true,});


  const downloadFile = React.useCallback((data) => {
    setLoadedDonwloadFile(false);
    setLoadingDonwloadFile(false);

    const blob = new Blob([b64toBlob(data.sDocBin)]);
    const fileName = `${data.sNomDoc}.${data.sExtensionDoc}`;
    const link = document.createElement('a');
  
    link.href = URL.createObjectURL(blob);
    link.target = '_blank';
    link.download = fileName;
  
    document.body.append(link);
    link.click();
    link.remove();
  
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  }, []);
  
  const b64toBlob = (b64Data, sliceSize = 512) => {
    const byteCharacters = atob(b64Data.replaceAll('-', '+').replaceAll('_', '/'));
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
  
      for (let i = 0; i < slice.length; i += 1) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays);
    return blob;
  };

  const fetchListDocument = React.useCallback(() => {
    const data = {
      "flag":"LIST-DOCUMENT-2",
      "data": {
        "sCle": localStorage.getItem('token'),
        "sOpe": '11-2',
        "nTaille": 0,
        "Data": {},
        "sCP":"drgdc"
      }
    };

    sendDataDoc(JSON.stringify(data));
  }, [sendDataDoc]);

  const fetchAddDocument = React.useCallback(async (values) => {

    const data = {
      "sCle": localStorage.getItem('token'),
      "sOpe":"2",
      "nTaille":0,
      "Data": {
        ...values,
        ...{nIDGrp: selectedGroup?.nIDGrp}
      }, 
      "sCP":"drgdc"
    };

    sendDataDoc(JSON.stringify({flag: ADD_DOCUMENT, data}));
    
  }, [sendDataDoc, selectedGroup]);

  const fetchListGroup = React.useCallback(() => {

    const data = {
      "sCle": localStorage.getItem('token'),
      "sOpe":"11",
      "nTaille":0,
      "Data":{}, 
      "sCP":"drggp"
    };

    sendDataGroup(JSON.stringify({flag: LIST_GROUP_1, data}));
  }, [sendDataGroup]);

  const fetchDownloadDocument = React.useCallback(async (nIDMetaData) => {

    const data = {
      "sCle": localStorage.getItem('token'),
      "sOpe":"1-2",
      "nTaille":0,
      "Data": {
        "nIDMetaData": nIDMetaData
      }, 
      "sCP":"drgdc"
    };

    sendDownloadDoc(JSON.stringify({flag: DOWNLOAD_DOCUMENT, data}));
    
  }, [sendDownloadDoc]);

  const fetchReadDocument = React.useCallback(async (nIDMetaData) => {

    const data = {
      "sCle": localStorage.getItem('token'),
      "sOpe":"1-1",
      "nTaille":0,
      "Data": {
        "nIDMetaData": nIDMetaData
      }, 
      "sCP":"drgdc"
    };

    sendReadDoc(JSON.stringify({flag: READ_DOCUMENT, data}));
    
  }, [sendReadDoc]);


  const handleResponseDoc = React.useCallback(() => {
    if (responseDoc !== null) {
      const response = JSON.parse(responseDoc.data);
      
      if(response.status && response.status === 200) {
        if(!loadedList) {
          setLoadedList(true)

          setDataDoc(response.data.Data)
        }
      }
      if(response.status && response.status === 400){
        notification.error({message: response.data.sMsg});
      }
    }
  }, [loadedList, responseDoc, setDataDoc])

  const handleResponseDownloadDoc = React.useCallback(() => {
    if (responseDownloadDoc !== null) {
      const response = JSON.parse(responseDownloadDoc.data);
      
      if(response.status && response.status === 200) {
        if(!loadedDonwloadFile) {
          setLoadedDonwloadFile(true)

          setDataDownloadDoc(response.data.Data)
          downloadFile(response.data.Data);
          setDataDownloadDoc(null)
          setIdMetadata(0);
        }
      }
      if(response.status && response.status === 400){
        notification.error({message: response.data.sMsg});
        // setDataDoc([]);
      }
    }
  }, [responseDownloadDoc, setDataDownloadDoc, downloadFile, loadedDonwloadFile])

  const handleResponseReadDoc = React.useCallback(() => {
    if (responseReadDoc !== null) {
      const response = JSON.parse(responseReadDoc.data);
      
      if(response.status && response.status === 200) {
        if(!loadedReadDoc) {
          setLoadedReadDoc(true)

          setReadDoc(response.data.Data)
          formRef2?.current?.setFieldsValue(response.data.Data)
          setIdMetadataRead(0);
        }
      }
      if(response.status && response.status === 400){
        notification.error({message: response.data.sMsg});
        // setDataDoc([]);
      }
    }
  }, [loadedReadDoc, responseReadDoc])

  const handleResponseGroup = React.useCallback(() => {
    if (responseGroup !== null) {
      const response = JSON.parse(responseGroup.data);
      
      if(response.status && response.status === 200) {
        if(!loadedListGroup) {
          setLoadedListGroup(true)

          setDataGroup(response.data.Data)
        }
      }
      if(response.status && response.status === 400){
        notification.error({message: response.data.sMsg});
        // setDataGroup([]);
      }
    }
  }, [loadedListGroup, responseGroup, setDataGroup])




  useEffect(() => {
    if (responseDoc === null && dataDoc === null) {
      fetchListDocument();
    }
    if (responseDownloadDoc === null && dataDownloadDoc === null && idMetadata !== 0) {
      fetchDownloadDocument(idMetadata);
    }
    if (responseReadDoc === null && readDoc === null && idMetadataRead !== 0) {
      fetchReadDocument(idMetadataRead);
    }
    if (responseGroup === null && dataGroup === null) {
      fetchListGroup();
    }


    handleResponseDoc();
    handleResponseGroup();
    handleResponseDownloadDoc();
    handleResponseReadDoc();

    setRoleAuth(userAuth?.RL);
  }, [
    fetchReadDocument,
    handleResponseReadDoc,
    dataDownloadDoc,
    fetchDownloadDocument,
    handleResponseDownloadDoc,
    idMetadata,
    responseDownloadDoc,
    roleAuth, 
    dataDoc, 
    dataGroup, 
    fetchListDocument, 
    handleResponseDoc, 
    responseDoc, 
    userAuth, 
    fetchListGroup, 
    handleResponseGroup, 
    responseGroup,
    idMetadataRead,
    readDoc,
    responseReadDoc
  ]);


  const save = (value) => {
    console.log('DATA', value)
    fetchAddDocument(value)
  }

  const columns = [
    {
      title: 'Nom document',
      dataIndex: 'sNomDoc',
      sorter: (a, b) => a.sNomDoc.localeCompare(b.sNomDoc),
      render: (text, row) => {
        return (props.listUrlInName ? (
          <Link
            to={{
              pathname: `/app/document`,
              state: { modal: true },
            }}
          >
            {text}
          </Link>
        )
          : text)
      }
    },
    {
      title: 'Type du document',
      dataIndex: 'sExtensionDoc',
      sorter: (a, b) => a.sExtensionDoc.localeCompare(b.sExtensionDoc),
    },
    {
      title: 'Code type',
      dataIndex: 'sCodeTypeDoc',
      sorter: (a, b) => a.sCodeTypeDoc.localeCompare(b.sCodeTypeDoc),
    },
    {
      title: 'Version',
      dataIndex: 'sVersionDoc',
      sorter: (a, b) => a.sVersionDoc.localeCompare(b.sVersionDoc),
    },
    {
      title: 'Date de création',
      dataIndex: 'dhDateEnrExt',
      key: 'dhDateEnrExt',
      render: (text) => <Moment format={'DD/MM/YYYY'}>{text}</Moment>
    },
    {
      title: 'Actions',
      dataIndex: 'idRapport',
      width: props.notShowDetail == null ? '300px' : '150px',
      render: (text, row) => {
        return (
          <>
            {
              selectedGroup?.bPeutTelecharger ? (
                <Button
                  type="primary"
                  className="btn-shadow mr-2"
                  onClick={() => {
                    fetchDownloadDocument(row.nIDMetaData);
                    setIdMetadata(row.nIDMetaData);
                    setLoadingDonwloadFile(true)
                  }}
                  loading={loadingDonwloadFile}
                >Télécharger</Button>
              ) : (<></>)
            }

            {
              selectedGroup?.bPeutLire ? (
                <Button
                  type="primary"
                  className="btn-shadow"
                  onClick={() => {
                    fetchReadDocument(row.nIDMetaData);
                    setOpenDetail(true);
                    setIdMetadata(row.nIDMetaData);
                  }}
                >Détail</Button>
              ) : (<></>)
            }
          </>
        );
      }
  }];

  const normFile = (info) => {
    formRef.current.setFieldsValue({
      sDocBin: null,
    });
    if (info.fileList[0] != null) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64 = e.target.result.split(',').pop();
        info.fileList[0].base64 = base64.replaceAll('+', '-').replaceAll('/', '_');
        formRef.current.setFieldsValue({
          sDocBin: `${info.fileList[0].base64}`,
          sNomDoc: info.fileList[0].name,
          sCodeTypeDoc: 'AUT'
        });
      };
      reader.readAsDataURL(info.fileList[0].originFileObj);
      const newFile = info.fileList[0];

      return newFile;
    }
    return {};
  };

  const checkFileIsAllowed = (file) => {
    if (!file.type.startsWith('image/') && file.type !== 'application/pdf') {
      notification.error({message: 'Extension non prise en compte'})
    }
    return false;
  };

  return (
    <>
      <>
        <Card style={{border: '1px dashed #D7D7D7'}}>
          <label>Choisir votre groupe </label>
          <Select
            className='w-25'
            options={dataGroup ? dataGroup.map(d => ({ label: `${d.sNomGrp}`, value: d.nIDGrp })) : []}
            onChange={value => {
              setSelectedGroup(dataGroup.find(dg => dg.nIDGrp === value));
            }}
          />
        </Card>
        {
          selectedGroup ? (
            <>
              <Card className='py-2' bodyStyle={{padding: '0px 25px'}} style={{border: '1px dashed #D7D7D7'}}>
                <div className='d-flex flex-row justify-content-between'>
                  <Title level={3} className='my-1'>{selectedGroup.sNomGrp}</Title>
                  {
                    selectedGroup.bPeutTelecharger ? (
                      <div className='d-flex justify-content-between align-items-center'>
                        <Button
                            type="primary"
                            onClick={() => {
                              setOpen(true)
                            }}
                            className="btn-shadow"
                            >
                            + Ajouter
                        </Button>
                      </div>
                    ) : (<></>)
                  }
                </div>
              </Card>
              <Card className='py-2' bodyStyle={{padding: '0px 25px'}}  style={{border: '1px dashed #D7D7D7'}}>
                <Table
                  className='mt-3'
                  columns={columns}
                  dataSource={dataDoc}
                  rowKey="idRapport"
                />
              </Card>
            </>
          ) : (<></>)
        }
        <Modal
          title="Ajouter un document"
          centered
          open={open}
          onCancel={() => setOpen(false)}
          footer={null}
        >
          {selectedGroup?.nIDGrp}
          <Form
            ref={formRef}
            onFinish={save}
            layout="vertical"
          >
            <Form.Item label="Titre" name='sTitreDoc'>
              <Input />
            </Form.Item>
            <Form.Item hidden label="Groupe" name='nIDGrp' defaultValue={selectedGroup?.nIDGrp}>
              <Input value={selectedGroup?.nIDGrp} />
            </Form.Item>
            <Form.Item label="Nom du document" name='sNomDoc'>
              <Input readOnly />
            </Form.Item>
            <Form.Item 
              name="sDocBin"
              label="Uploader un document"
            >
              <Upload
                multiple={false}
                name="sDocBin"
                listType="picture"
                // fileList={fileList}
                beforeUpload={checkFileIsAllowed}
                onChange={normFile}
                maxCount={1}
                onRemove={() => {
                  formRef.current.setFieldsValue({
                    sDocBin: null,
                  });
                }}
              >
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </Form.Item>
            <Form.Item label="Version du document" name='sVersionDOc'>
              <Input />
            </Form.Item>
            <Form.Item label="Commentaire" name='sCommentaire'>
              <Input.TextArea />
            </Form.Item>
            <Form.Item hidden name='sCodeTypeDoc' defaultValue='AUT'>
              <Input />
            </Form.Item>
            <Form.Item label="Mots cles" name='tabMotsCles'>
              <Select
                mode="tags"
                style={{width: '100%'}}
                placeholder="Ajouter des mots cles ici"
                options={[]}
              />
            </Form.Item>
            <Form.Item>
              <Button type='primary' htmlType='submit'>Valider</Button>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="Detail du document"
          centered
          open={openDetail}
          onCancel={() => setOpenDetail(false)}
          footer={null}
        >
          <Form
            ref={formRef2}
            onFinish={save}
            layout="vertical"
            initialValues={readDoc}
          >
            <Form.Item label="Titre" name='sTitreDoc'>
              <Input value={readDoc?.sTitreDoc} />
            </Form.Item>
            <Form.Item hidden label="Groupe" name='nIDGrp' defaultValue={selectedGroup?.nIDGrp}>
              <Input value={selectedGroup?.nIDGrp} />
            </Form.Item>
            <Form.Item label="Nom du document" name='sNomDoc'>
              <Input readOnly />
            </Form.Item>
            <Form.Item 
              name="sDocBin"
              label="Uploader un document"
            >
              <Upload
                multiple={false}
                name="sDocBin"
                listType="picture"
                beforeUpload={checkFileIsAllowed}
                onChange={normFile}
                maxCount={1}
                onRemove={() => {
                  formRef.current.setFieldsValue({
                    sDocBin: null,
                  });
                }}
              >
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </Form.Item>
            <Form.Item label="Version du document" name='sVersionDOc'>
              <Input />
            </Form.Item>
            <Form.Item label="Commentaire" name='sCommentaire'>
              <Input.TextArea />
            </Form.Item>
            <Form.Item hidden name='sCodeTypeDoc' defaultValue='AUT'>
              <Input />
            </Form.Item>
            <Form.Item label="Mots cles" name='tabMotsCles'>
              <Select
                mode="tags"
                style={{width: '100%'}}
                placeholder="Ajouter des mots cles ici"
                options={[]}
              />
            </Form.Item>
            <Form.Item>
              <Button type='primary' htmlType='submit'>Valider</Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    </>
  )
}

export default Document