import React, { useState, useEffect } from 'react';
import { Card, Button, Table, Typography, Select, Modal, message, Popconfirm, notification, Switch, Form } from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import useWebSocket from 'react-use-websocket';
import { LIST_ACTOR } from '../../../constants/flags';
import { ACTOR_URI, DOCUMENT_URI, GROUP_URI } from '../../../constants/endpoints';
import { suppressDeprecationWarnings } from 'moment';

const { Title } = Typography;
const { Option } = Select;

const Users = (props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadedList, setLoadedList] = useState(false);
  const [loadedListGroup, setLoadedListGroup] = useState(false);
  const [loadedAttribute, setLoadedAttribute] = useState(false);

  const [word, setWord] = useState("");

  const [data, setData] = useState(null);
  const [dataGroup, setDataGroup] = useState(null);

  const [selectedUser, setSelectedUser] = useState({});
  const [selectedValues, setSelectedValues] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenGroup, setIsModalOpenGroup] = useState(false);
  const [userAuth, setUserAuth] = useState(JSON.parse(localStorage.getItem('AUTH_USER')));
  const [roleAuth, setRoleAuth] = useState('');

  const { sendMessage: sendDataActor, lastMessage: responseActor } = useWebSocket(ACTOR_URI, {shouldReconnect: (closeEvent) => true});
  const { sendMessage: sendDataAttribuer, lastMessage: responseAttribuer } = useWebSocket(GROUP_URI, {shouldReconnect: (closeEvent) => true});
  const { sendMessage: sendDataGroup, lastMessage: responseGroup } = useWebSocket(GROUP_URI , {shouldReconnect: (closeEvent) => true});

  const fetchListActor = React.useCallback(async () => {
    setLoading(true);
    const data = {
      "sCle": localStorage.getItem('token'),
      "sOpe":"11-1",
      "nTaille":0,
      "Data":{}, 
      "sCP":"drga"
    };

    sendDataActor(JSON.stringify({flag: LIST_ACTOR, data}));
    
  }, [sendDataActor]);

  const fetchListGroup = React.useCallback(async () => {
    setIsModalOpenGroup(true);
    setLoading(true);
    const data = {
      "sCle": localStorage.getItem('token'),
      "sOpe":"11-2",
      "nTaille":0,
      "Data":{}, 
      "sCP":"drggp"
    };

    sendDataGroup(JSON.stringify({flag: 'LIST-GROUP-2', data}));
}, [sendDataGroup]);

  const handleResponseActor = React.useCallback(() => {
    if (responseActor !== null) {
      const response = JSON.parse(responseActor.data);

      setLoading(false);
      
      if(response.status && response.status === 200) {
        if(!loadedList) {
          setLoadedList(true)

          setData(response.data.Data)
        }
      }
      if(response.status && response.status === 400){
        notification.error({message: response.data.sMsg});
        setData([]);
      }
    }
  }, [loadedList, responseActor, setData])

  const handleResponseGroup = React.useCallback(() => {
    if (responseGroup !== null) {
      const response = JSON.parse(responseGroup.data);

      setLoading(false);
      
      if(response.status && response.status === 200) {
        if(!loadedListGroup) {
          setLoadedList(true)
          console.log(response.data.Data)
          setDataGroup(response.data.Data);
        }
      }
      if(response.status && response.status === 400){
        notification.error({message: response.data.sMsg});
        setDataGroup([]);
      }
    }
  }, [loadedListGroup, responseGroup, setDataGroup]);

  const handleResponseAttribuer = React.useCallback(() => {
    if (responseAttribuer !== null) {
      const response = JSON.parse(responseAttribuer.data);

      setLoadedAttribute(false);
      
      if(response.status && response.status === 200) {
        if(!loadedAttribute) {

          setLoadedAttribute(true)
          notification.success({message: "Opération avec succès."});
          
          setTimeout(() => {
            navigate('/groupes');
          }, 500);
        }
      }
      if(response.status && response.status === 400){
        notification.error({message: response.data.sMsg});
        // setData([]);
      }
    }
  }, [loadedAttribute, responseAttribuer]);
  useEffect(() => {
    if (responseActor === null && data === null) {
      fetchListActor();
    }

    handleResponseActor();
    handleResponseGroup();

    handleResponseAttribuer();

    setRoleAuth(userAuth?.RL);
  }, [handleResponseAttribuer, data, fetchListActor, userAuth, responseActor, handleResponseActor, handleResponseGroup]);

  const columns = [
    {
      title: 'Nom',
      dataIndex: 'sNom',
      key: 'sNom',
      sorter: (a, b) => a.sNom.localeCompare(b.sNom),
      sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Prénom',
        dataIndex: 'sPrenom',
        key: 'sPrenom',
        sorter: (a, b) => a.sPrenom.localeCompare(b.sPrenom),
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Mail',
        dataIndex: 'sMail',
        key: 'sMail',
        sorter: (a, b) => a.sMail.localeCompare(b.sMail),
        sortDirections: ['descend', 'ascend'],
    },
    // {
    //     title: 'Groupe',
    //     dataIndex: 'sGroupe',
    //     key: 'sGroupe',
    //     sorter: (a, b) => a.sGroupe.localeCompare(b.sGroupe),
    //     sortDirections: ['descend', 'ascend'],
    //     defaultSortOrder: 'ascend',
    // },
    {
        title: 'Actions',
        dataIndex: 'id',
        key: 'id',
        render: (text, row) => {
          return (
            <>
                <Button className="mr-3" onClick={() => showModal(row.id)} type="primary" icon={<EyeOutlined />} size="small" style={{width: '24px', height: '24px'}}/>
                <Popconfirm
                    title="Suppression utilisateur"
                    description="Vous êtes sûre de vouloir supprimer ce utilisateur ?"
                    onConfirm={() => deleteItem(row.id)}
                    onCancel={cancel}
                    okText="Supprimier"
                    cancelText="Annuler"
                >
                    <Button
                      className="" type="primary" icon={<DeleteOutlined />} size="small" danger style={{width: '24px', height: '24px'}}/>
                </Popconfirm>
            </>
          );
        },
    },
  ];

  const onSearch = (value) => {
    const text = value.toLowerCase();
    setWord(text);
  };

  const handleSelectChange = (selectedValues) => {
    setSelectedValues(selectedValues);
  };

  const showModal = (userId) => {
    let selected = data.find((item) => userId === item.id);
    setSelectedUser(selected);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  
  const handleCancelGroup = () => {
    setIsModalOpenGroup(false);
  };
  const deleteItem = (itemId) => {
    // Use Array.filter to create a new array without the item to be deleted
    const newData = data.filter(item => item.id !== itemId);
    
    // Update the state with the new data
    setData(newData);
    message.success('Utilisateur supprimé');
  };

  const cancel = (e) => {
    console.log(e);
  };

  const submitAttribuer = (values) => {
    
    let stGrp = {
      "nIDGrp" : values.nIDGrp
    }

    let stActeur = {
      "nIDActeur" : values.nIDActeur
    }
    const dataSend = {
      "stGrp": stGrp,
      "tabActDroit" : [
          {
            "stActeur" : stActeur,
            "dhDateDeb" : values.dhDateDeb,
            "dhDateFin" : values.dhDateFin,
            "bPeutLister" : (values.bPeutLister) ? true : false,
            "bPeutLire" : (values.bPeutLire) ? true : false,
            "bPeutDeposer" : (values.bPeutDeposer) ? true : false,
            "bPeutTelecharger" : (values.bPeutTelecharger) ? true : false,
          },
      ]
    }
    console.log(dataSend);
    const data = {
      "sCle": localStorage.getItem('token'),
      "sOpe": '33',
      "nTaille": 0,
      "Data": dataSend,
      "sCP":"drggp"
    };
    sendDataAttribuer(JSON.stringify({"flag":"ADD-ACTOR-GROUP", data}));
  };
  return (
    <div>
        {
            (roleAuth === 'ADMIN') ? (
                <>
                    <Card className='py-2' bodyStyle={{padding: '0px 25px'}} style={{border: '1px dashed #D7D7D7'}}>
                        <div className='d-flex justify-content-between py-2 align-items-center'>
                            <Title level={3} className='my-1'>Gestion utilisateurs</Title>
                        </div>
                    </Card>
                    <Card className='py-2' bodyStyle={{padding: '0px 25px'}} style={{border: '1px dashed #D7D7D7'}}>
                        <div className='d-flex justify-content-between py-2 align-items-center'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                      fetchListGroup();
                                    }}
                                    className="btn-shadow"
                                    >
                                    + Attribuer un acteur dans un groupe
                                </Button>
                            </div>
                        </div>
                    </Card>
                    <Card style={{border: '1px dashed #D7D7D7'}}>
                    <Table
                        columns={columns}
                        dataSource={data !== null && data?.filter((item) => {
                        if (
                            item.sNom.toLowerCase().includes(word)
                        ) {
                            return true;
                        }
                        return false;
                        })}
                        pagination={{ hideOnSinglePage: true, pageSize: 10 }}
                    />
                    </Card>
                    <Modal title="Information utilisateur" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                        <p>{`Nom : ${selectedUser.sNom}`}</p>
                        <p>{`Prénom : ${selectedUser.sPrenom}`}</p>
                        <p>{`Mail : ${selectedUser.sMail}`}</p>
                        <p>{`Groupe : ${selectedUser.sGroupe}`}</p>
                    </Modal>

                    <Modal title="Attribution groupe" 
                    open={isModalOpenGroup} 
                    onCancel={handleCancelGroup}
                    maskClosable={false}
                    footer={null} 
                    >
                        <Form
                            form={form}
                            layout="vertical"
                            name="login-form"
                            onFinish={(e) => submitAttribuer(e)}
                            data-testid="groupForm"
                            footer={null}
                        >
                            <Form.Item
                                name="nIDGrp"
                                label="Groupe"
                                className="mb-5"
                                rules={[
                                {
                                    required: true,
                                },
                                ]}
                            >
                                <Select
                                    placeholder="Séléctionner groupe"
                                    allowClear

                                    options={dataGroup ? dataGroup.map(d => ({ label: `${d.sNomGrp}`, value: d.nIDGrp })) : []}
                                />
                            </Form.Item>
                            <Form.Item
                                name="nIDActeur"
                                label="Acteur"
                                className="mb-5"
                                rules={[
                                {
                                    required: true,
                                },
                                ]}
                            >
                                <Select
                                    placeholder="Séléctionner acteur"
                                    allowClear

                                    options={data ? data.map(d => ({ label: `${d.sPrenom} ${d.sNom}`, value: d.nIDActeur })) : []}
                                />
                            </Form.Item>
                            <Form.Item
                                name="dhDateDeb"
                                label="Date début"

                            >
                                <input type="date" className="ant-input w-75" />
                            </Form.Item>
                            <Form.Item
                                name="dhDateFin"
                                label="Date fin"

                            >
                                <input type="date" className="ant-input w-75" />
                            </Form.Item>
                            <Form.Item
                                name="bPeutLister"
                                label="Peut Lister"
                                className="mb-5"
                                valuePropName="checked"
                            >
                                <Switch onChange={(e) => null} />
                            </Form.Item>
                            <Form.Item
                                name="bPeutLire"
                                label="Peut Lire"
                                className="mb-5"
                                valuePropName="checked"
                            >
                                <Switch onChange={(e) => null} />
                            </Form.Item>
                            <Form.Item
                                name="bPeutDeposer"
                                label="Peut Depose"
                                className="mb-5"
                                valuePropName="checked"
                            >
                                <Switch onChange={(e) => null} />
                            </Form.Item>
                            <Form.Item
                                name="bPeutTelecharger"
                                label="Peut Telecharger"
                                className="mb-5"
                                valuePropName="checked"
                            >
                                <Switch onChange={(e) => null} />
                            </Form.Item>
                            <Form.Item className="mt-5" style={{ textAlign: 'center' }}>
                                <Button id="submit" type="primary" htmlType="submit" block className="w-50">
                                    Enregistrer
                                </Button>
                            </Form.Item>
                        </Form>
                    </Modal>
                </>
            ) : (
                <></>
            )
        }
    </div>
  );
};


export default Users
